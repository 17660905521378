<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Servicios</h4>
            <div class="small text-muted">Administración de servicios</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Categoría">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <v-select :options="arr.filter.category" v-model="filter.category" placeholder="Categoría" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>    
          <b-col sm="2">
            <v-select :options="arr.filter.status" v-model="filter.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>              
          <b-col sm="3">            
            <b-button variant="outline-dark" @click="filterService()">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">        
        <b-tabs pills card justified active-nav-item-class="font-weight-bold text-uppercase text-white">
          <b-tab title="En Servicios" active>
            <b-row>
              <b-col>
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"                    
                        :current-page="table.currentPage"
                        :per-page="table.perPage"  
                        :busy="table.isBusy"                                      
                        v-if="table.items.length || table.isBusy">                                           

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(category)="data">
                      {{data.item.category.name}}
                    </template>

                    <template v-slot:cell(name)="data">                  
                      <div :title="data.item.display_sales_detail" style="cursor:pointer;">            
                        <span v-if="data.item.code" class="crud-table-service-code">
                          {{data.item.code}}<br>                   
                        </span>
                        {{data.item.name}}
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">                      
                      <span v-if="data.item.observations">                                                    
                        <b-badge variant="primary" :id="'popover-service-observations-' + data.item.id" class="popover-observations">
                          <b-icon icon="card-heading"></b-icon> 
                        </b-badge>
                        <b-popover :target="'popover-service-observations-' + data.item.id" variant="default" triggers="hover">                            
                          <div v-html="data.item.observations.replace(/\n/g, '<br>')"></div>
                        </b-popover>                                                    
                      </span>                          
                    </template>                    

                    <template v-slot:cell(mode)="data">
                      <div v-html="getModeName(data.item.mode)"></div>
                    </template>                

                    <template v-slot:cell(renewal)="data">
                      <div v-if="data.item.mode=='days' || data.item.mode=='months' || data.item.mode=='years'">
                        <b-avatar v-if="data.item.automatic_renewal" 
                                  variant="secondary" 
                                  size="md"
                                  :title="'Renovación Automática cada ' + data.item.renewal + ' ' + getModeName(data.item.mode)"
                                  class="align-baseline crud-services-legend-renewal"
                                  :text="data.item.renewal.toString()"
                                  badge-variant="success"
                                  badge="A">
                        </b-avatar>
                        <b-avatar v-else 
                                  variant="secondary" 
                                  size="md"
                                  :title="'Renovación Manual cada ' + data.item.renewal + ' ' + getModeName(data.item.mode)"
                                  class="align-baseline crud-services-legend-renewal"
                                  :text="data.item.renewal.toString()"
                                  badge-variant="danger"
                                  badge="M">
                        </b-avatar>                                                            
                      </div>
                      <div v-else>
                        -
                      </div>                  
                    </template>   

                    <template v-slot:cell(prices)="data">                               
                      <div v-if="data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id">                      
                          <div v-if="element.list">
                            <b :title="element.list.name" v-b-tooltip.hover>
                              <span v-if="element.list.reference">
                                {{element.list.reference.substring(0,5)}}
                              </span>
                              <span v-else>
                                {{element.list.name.substring(0,5)}}
                              </span>                              
                            </b> : 
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}                      
                          </div>                      
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesServices(data.item)">Ver Listas</a>
                      </div>
                    </template>

                    <template v-slot:cell(mode_projects)="data">                    
                      <span v-if="data.item.mode_projects == 'with_expiration'" class="crud-services-table-project">
                          <b-icon icon="calendar2-range-fill"
                                  variant="dark" 
                                  font-scale="1.5"
                                  v-b-tooltip.hover                               
                                  title="Crea Proyecto CON Vencimiento"></b-icon>                      
                      </span>  
                      <span v-if="data.item.mode_projects == 'without_expiration'" class="crud-services-table-project">
                          <b-icon icon="arrow-clockwise" 
                                  variant="dark"
                                  font-scale="1.5"
                                  v-b-tooltip.hover 
                                  title="Crea Proyecto SIN Vencimiento"></b-icon>                      
                      </span>                    
                    </template>

                    <template v-slot:cell(instances)="data">
                      <div v-if="isProcessInstances && data.item.type_instances">
                        <span v-if="data.item.type_instances == 'system'">
                          <u>SISTEMA</u>
                        </span>       
                        <span v-if="data.item.type_instances == 'other_system'">
                          <u>OTROS</u>
                        </span>                           
                      </div>           
                    </template>

                    <template v-slot:cell(active)="data">
                      <b-badge variant="success" v-if="data.item.active">SI</b-badge>
                      <b-badge variant="danger" v-else>NO</b-badge>
                    </template>                   

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="setPricesServices(data.item)" v-if="modules.showPrices">
                          <i class="fa fa-dollar crud-services-action-icon"></i> Precios
                        </b-dropdown-item>            

                        <b-dropdown-header v-if="isProcessSales">Presupuesto</b-dropdown-header>                         
                        <b-dropdown-item @click="setTemplate(data.item)" v-if="isProcessSales">
                          <i class="fa fa-file-text-o crud-services-action-icon"></i> Plantilla 
                        </b-dropdown-item>                           

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Solo en ERP">
            <b-row>
              <b-col>
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="tableERP.items"
                        :fields="tableERP.fields"                    
                        :current-page="tableERP.currentPage"
                        :per-page="tableERP.perPage"  
                        :busy="tableERP.isBusy"                                      
                        v-if="tableERP.items.length || tableERP.isBusy">                                           

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(category)="data">
                      {{data.item.category.name}}
                    </template>

                    <template v-slot:cell(name)="data">      
                      <div :title="data.item.display_sales_detail" style="cursor:pointer;">            
                        <span v-if="data.item.code" class="crud-table-service-code">
                          {{data.item.code}}<br>                   
                        </span>
                        {{data.item.name}}
                      </div>
                    </template>                

                    <template v-slot:cell(observations)="data">                      
                      <span v-if="data.item.observations">                                                    
                        <b-badge variant="primary" :id="'popover-erp-observations-' + data.item.id" class="popover-observations">
                          <b-icon icon="card-heading"></b-icon> 
                        </b-badge>
                        <b-popover :target="'popover-erp-observations-' + data.item.id" variant="default" triggers="hover">                            
                          <div v-html="data.item.observations.replace(/\n/g, '<br>')"></div>
                        </b-popover>                                                    
                      </span>                          
                    </template>                        

                    <template v-slot:cell(prices)="data">                               
                      <div v-if="data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id">                      
                          <div v-if="element.list">
                            <b :title="element.list.name" v-b-tooltip.hover>
                              <span v-if="element.list.reference">
                                {{element.list.reference.substring(0,5)}}
                              </span>
                              <span v-else>
                                {{element.list.name.substring(0,5)}}
                              </span>                              
                            </b> : 
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}                      
                          </div>                      
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesServices(data.item)">Ver Listas</a>
                      </div>
                    </template>
                                      
                    <template v-slot:cell(active)="data">
                      <b-badge variant="success" v-if="data.item.active">SI</b-badge>
                      <b-badge variant="danger" v-else>NO</b-badge>
                    </template>                   

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="setPricesServices(data.item)" v-if="modules.showPrices">
                          <i class="fa fa-dollar crud-services-action-icon"></i> Precios
                        </b-dropdown-item>            

                        <b-dropdown-header v-if="isProcessSales">Presupuesto</b-dropdown-header>                         
                        <b-dropdown-item @click="setTemplate(data.item)" v-if="isProcessSales">
                          <i class="fa fa-file-text-o crud-services-action-icon"></i> Plantilla 
                        </b-dropdown-item>                                    

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>            
          </b-tab>
        </b-tabs>        
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterService()" />
            </nav>            
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="6" class="mb-3">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Activar
                </b-form-checkbox>            
              </b-col>      

              <b-col md="6" class="mb-3" title="Este servicio solo está disponible para el módulo ERP">                 
                <b-form-checkbox v-model="crud.form.show_only_erp" 
                                  switch                                  
                                  size="sm" 
                                  class="pull-left">
                  Utilizar solo en ERP
                </b-form-checkbox>                            
              </b-col>                    

              <b-col md="12">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>
              </b-col>         

              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>         

              <b-col md="12">
                <FindObject render="inline"
                            type="servicesCategory" 
                            @select-object="loadCategory($event)" 
                            :valueID="crud.form.category_id"/>                 
              </b-col>                            
            </b-row>
          </b-tab>

          <b-tab no-body title="Medición" v-if="!crud.form.show_only_erp">
            <b-row>
              <b-col md="6">
                <b-form-group label="Medición"
                              description="El precio es aplicado según la medición seleccionada.">
                  <b-form-select v-model="crud.form.mode" 
                                :options="arr.select.mode"
                                stacked
                                @input="changeMode()">
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="crud.form.mode == 'days' || crud.form.mode == 'months' || crud.form.mode == 'years'">
                <b-form-group label="Renovación" 
                              :description="'Cantidad de ' + this.getModeName(this.crud.form.mode) + ' para renovar el servicio. Si la renovación es cero (0), el sistema no calcula la renovación para el item'">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.renewal"
                                required
                                min="0"                          
                                placeholder="Ingresar días de renovación">
                  </b-form-input>
                </b-form-group>
              </b-col>


              <b-col md="12" v-if="crud.form.mode == 'days' || crud.form.mode == 'months' || crud.form.mode == 'years'">
                <b-form-checkbox v-model="crud.form.automatic_renewal" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Renovación Automática
                </b-form-checkbox>            
              </b-col>      
            </b-row>
          </b-tab>

          <b-tab no-body title="Proyecto" v-if="isProcessProject && !crud.form.show_only_erp">
            <b-row>
              <b-col md="12">
                  <b-form-group label="Proyecto"
                                description="Crea proyecto a partir del alta del servicio a un cliente.">
                    <b-form-select v-model="crud.form.mode_projects" 
                                  :options="arr.select.modeProjects"
                                  stacked>
                    </b-form-select>
                  </b-form-group>
                </b-col>          
            </b-row>
          </b-tab>

          <b-tab no-body title="Instancias" v-if="isProcessInstances && !crud.form.show_only_erp">
            <b-row>
              <b-col md="12">
                  <b-form-group label="Tipo de Instancias"
                                description="Se muestra disponible en la integración de instancias.">
                    <b-form-select v-model="crud.form.type_instances" 
                                  :options="arr.select.typeInstances"
                                  stacked>
                    </b-form-select>
                  </b-form-group>
                </b-col>          
            </b-row>
          </b-tab>  

          <b-tab no-body title="Fiscal" v-if="isProcessSales">
            <b-row>
              <b-col md="12">
                <b-form-group label="Detalle para comprobante de venta">
                  <b-form-textarea  rows="3"                                     
                                    size="sm"
                                    no-resize
                                    v-model="crud.form.display_sales_detail"
                                    required
                                    placeholder="Ingresar un detalle">
                  </b-form-textarea>
                </b-form-group>
              </b-col>     

              <b-col lg="12">
                <FindObject render="search"
                            type="erpIvaConditions" 
                            @select-object="loadIvaConditions($event)" 
                            :valueID="crud.form.erp_iva_condition_id"/>                   
              </b-col>              
            </b-row>            
          </b-tab>       

          <b-tab no-body title="Cuenta Contable" v-if="isProcessAccounting">
            <b-row>
              <b-col lg="12">
                <FindObject render="search"
                        type="accountingAccountsImputation" 
                        @select-object="loadAccountingAccounts($event)" 
                        :valueID="crud.form.accounting_accounts_id"
                        :where="conditionAccountingAccountsFilters"/>
              </b-col>              
            </b-row>            
          </b-tab>     

          <b-tab no-body title="Observaciones">
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea  rows="12"                                     
                                    size="sm"
                                    no-resize
                                    v-model="crud.form.observations"
                                    required
                                    placeholder="Ingresar una observacion">
                  </b-form-textarea>
                </b-form-group>
              </b-col>     
            </b-row>            
          </b-tab>    
        </b-tabs>      
 
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-services-title" v-if="itemSelected">
              <div class="crud-services-title">
                <span v-if="itemSelected.code" class="crud-services-code">
                  {{this.itemSelected.code}} 
                </span><br>
                {{this.itemSelected.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="5">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="crud.formPrice.price_list_select" 
                        placeholder="Lista"
                        :disabled="crud.formPrice.id>0">
              </v-select>
            </b-form-group>
          </b-col>    
          <b-col md="4">
            <b-form-group label="Importe">
              <b-form-input type="number"
                            v-model="crud.formPrice.amount"
                            step="0.01"
                            placeholder="Ingresar el precio">
              </b-form-input>
            </b-form-group>            
          </b-col>
          <b-col md="3">                
            <b-button variant="dark" 
                      @click="saveFormPrice()"
                      title="Guardar Precio"
                      class="crud-services-button-save-price pull-right">
              <b-icon icon="plus" v-if="crud.formPrice.id==0"></b-icon>
              <i class="fa fa-save" v-else></i>
            </b-button> 
            <b-button variant="outline-danger" 
                      @click="cancelSaveFormPrice()"
                      title="Cancelar Edición"
                      class="crud-services-button-save-price pull-right mr-1"                      
                      v-if="crud.formPrice.id>0">
              <i class="fa fa-close"></i>
            </b-button>                                  
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-services-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">                
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-services-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                
              
              <template v-slot:cell(price)="data">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            text="Acción" 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPrice.id>0">
                  <b-dropdown-item @click="editPricesServices(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="removePricesServices(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este Servicio</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>

      <!-- CRUD TEMPLATE -->
      <b-modal v-model="modal.formTemplate.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close         
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.formTemplate.title}}
        </div>

        <b-row>
          <b-col lg="12">            
            <div v-if="modal.formTemplate.service && modal.formTemplate.service.category">
              <b>{{this.modal.formTemplate.service.code}}</b>
              <br>
              {{modal.formTemplate.service.category.name}} - {{this.modal.formTemplate.service.name}}
            </div>
          </b-col>          

          <b-col lg="12">
            <hr>
          </b-col>        

          <b-col lg="12">
            <b-card no-body>
              <b-tabs pills card vertical class="services-template-budget">
                <b-tab v-for="item in modal.formTemplate.tabs" 
                       :key="'dyn-tab-' + item.id" 
                       :title="item.reference">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Referencia">
                        <b-form-input type="text"
                                      size="sm"
                                      v-model="item.reference"
                                      required
                                      placeholder="Ingresar una referencia">
                        </b-form-input>
                      </b-form-group>
                    </b-col>  
                    <b-col md="6">
                      <b-button size="sm" variant="danger" class="float-right" @click="closeTemplateTab(item)">
                        <b-icon icon="trash"></b-icon> Quitar
                      </b-button>                      
                    </b-col>    
                  </b-row>
                  <b-row>                                      
                    <b-col md="12">
                      <b-form-group label="Título">
                        <b-form-input type="text"
                                      size="sm"
                                      v-model="item.title"
                                      required
                                      placeholder="Ingresar un título">
                        </b-form-input>
                      </b-form-group>
                    </b-col>    

                    <b-col md="12">
                      <b-form-group label="Observaciones">
                        <vue-editor class="display-template-ql-editor" 
                                    v-model="item.description">
                        </vue-editor>              
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>      
                
                <template #tabs-end>
                  <b-nav-item role="presentation" @click.prevent="newTemplateTab" href="#" title="Agregar nuevo bloque">
                    <b>+</b>
                  </b-nav-item>
                </template>   
                
                <template #empty>
                  <div class="text-center text-muted">
                    No hay bloques creados<br>
                    Crea uno nuevo desde el botón <b>+</b> de la barra de navegación.
                  </div>
                </template>                                   
              </b-tabs>
            </b-card>            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTemplate.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTemplate()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  import Storage from '@/handler/storageSession'
  import Function from '@/handler/funciones'  
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      FindObject,
      VueEditor
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.SERVICIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudServices',
          elements: {}
        }, 
        parameters: {
          integrateInstances: Helper.hasParametersAccess(Param.P59),          
        },                  
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,          
          tablePaginate: null,                
        },  
        tableERP : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,          
          tablePaginate: null,                
        },          
        tablePrices : {
          items: [],
          fields: [
            {key: 'list', label: 'Lista', class:"align-middle", width:"250px"},
            {key: 'price', label: 'Precio', class:"align-middle", width:"100px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ]
        },         
        crud: {
          form: {
            id: 0,
            name: '',  
            code: '',
            category: null,
            category_id: 0,
            mode: 'units',
            renewal: 0,
            active: true,
            automatic_renewal: false,
            mode_projects: '',
            erp_iva_condition: null,
            erp_iva_condition_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            type_instances: '',
            show_only_erp: false,
            display_sales_detail: '',
            observations: '',
          },  
          formPrice: {
            id: 0,
            services_id: 0,
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0
          },                      
        },
        modal: {
          form: {
            active: false,
            title: ''
          },  
          formPrice: {
            active: false,
            title: ''
          },    
          formTemplate: {
            service: null,                        
            tabs: [],
            tabCounter: 0,
            active: false,
            title: ''           
          },                             
        },    
        arr: {
          services: [],            
          select: {
            mode: [              
              { value: 'units', text: 'Unidad/es' },         
              { value: 'hours', text: 'Hora/s' },                                 
              { value: 'days', text: 'Día/s' },   
              { value: 'months', text: 'Mes/es' },   
              { value: 'years', text: 'Año/s' },   
            ],
            modeProjects: [
              { value: '', text: 'No crear proyecto' },   
              { value: 'with_expiration', text: 'Proyectos con Vencimiento' },   
              { value: 'without_expiration', text: 'Proyectos sin Vencimiento' },   
            ],
            listPrice: [],   
            typeInstances: [
              { value: '', text: 'No Vinculable' },
              { value: 'system', text: 'Sistemas' },
              { value: 'other_system', text: 'Otros Sistemas' },
            ] 
          },
          filter : {
            category: [],   
            status: [
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},                                          
            ],
          }
        },
        filter : {
          category: [],          
          status: [
            {code:true, label: 'Activo'}            
          ],
          show_only_erp: false,
        },        
        modulesActive: [], 
        modules: {
          showPrices: false
        },    
        itemSelected: null,                              
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {    
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.configAccess()

      this.loadFieldTable()
      this.filterLoad()
      this.filterService()
      this.loadListPrice() 
    },
    computed: {
      isProcessSales() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.VENTAS || element.id == Modules.PRESUPUESTOS) {
            status = true
          }
        })
        return status
      }, 
      isProcessAccounting() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {
            status = true
          }
        })
        return status
      },
      isProcessInstances(){
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.INSTANCIAS) {
            status = true
          }
        })
        return status && this.parameters.integrateInstances
      },
      isProcessProject(){
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.PROYECTOS) {
            status = true
          }
        })
        return status && this.parameters.integrateInstances
      },

      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
          return [
              {field: 'active', condition: true},
              {field: 'type', condition: 'Ingresos'}
          ];
      },
    },
    methods: {
      configAccess() {
        this.modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            this.modules.showPrices = true                             
          }
        })
      },   
             
      loadFieldTable() {
        // EN SERVICIOS
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle"})
        this.table.fields.push({key: 'category', label: 'Categoría', class:"align-middle"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle"})
        this.table.fields.push({key: 'observations', label: '', class:"align-middle", width:"20px"})
        this.table.fields.push({key: 'mode', label: 'Medición', class:"align-middle text-center"})
        this.table.fields.push({key: 'renewal', label: 'Renovación', class:"align-middle text-center"})
        this.table.fields.push({key: 'prices', label: 'Precios', class:"align-middle text-center"})

        if(this.isProcessProject) {
          this.table.fields.push({key: 'mode_projects', label: 'Proyecto', class:"align-middle text-center"})
        }

        if(this.isProcessInstances) {
          this.table.fields.push({key: 'instances', label: 'Instancias', class:"align-middle text-center"})
        }        

        this.table.fields.push({key: 'active', label: 'Activo', class:"align-middle text-center"})
        this.table.fields.push({key: 'f_action', label:'', class: 'align-middle'})

        // SOLO ERP
        this.tableERP.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle"})
        this.tableERP.fields.push({key: 'category', label: 'Categoría', class:"align-middle"})
        this.tableERP.fields.push({key: 'name', label: 'Nombre', class:"align-middle"})
        this.tableERP.fields.push({key: 'observations', label: '', class:"align-middle", width:"20px"})
                
        this.tableERP.fields.push({key: 'prices', label: 'Precios', class:"align-middle text-center"})
       
        this.tableERP.fields.push({key: 'active', label: 'Activo', class:"align-middle text-center"})
        this.tableERP.fields.push({key: 'f_action', label:'', class: 'align-middle'})        
      },         
      filterService(forceOpenPrices=false){
        this.table.isBusy = true  
        this.filter.show_only_erp = false
        var result = serviceAPI.filtrarService(this.filter, this.table.currentPage)        
        result.then((response) => {        
          var data = response.data
          this.table.tablePaginate = data
                              
          this.table.items = data.data          

          this.table.items.forEach(element => {            
            if(forceOpenPrices) {
              if(element.id == this.itemSelected.id) {
                this.setPricesServices(element)
              }              
            }              
          }); 

          this.table.isBusy = false                    

          this.saveFilterStorage()
        })
        .catch(error => {          
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });   
        
        this.tableERP.isBusy = true  
        this.filter.show_only_erp = true
        var resultERP = serviceAPI.filtrarService(this.filter, this.tableERP.currentPage)        
        resultERP.then((responseERP) => {        
          var data = responseERP.data
          this.tableERP.tablePaginate = data
                              
          this.tableERP.items = data.data          

          this.tableERP.items.forEach(element => {            
            if(forceOpenPrices) {
              if(element.id == this.itemSelected.id) {
                this.setPricesServices(element)
              }              
            }              
          }); 

          this.tableERP.isBusy = false                    

          this.saveFilterStorage()
        })
        .catch(error => {          
          this.tableERP.isBusy = false
          this.$awn.alert(Error.showError(error))
        });           
      },       
      add() {
        this.crud.form.id = 0
        this.crud.form.code = '' 
        this.crud.form.name = '' 
        this.crud.form.category = null
        this.crud.form.category_id = 0
        this.crud.form.mode = 'units'
        this.crud.form.renewal = 0
        this.crud.form.active = true
        this.crud.form.automatic_renewal = false
        this.crud.form.mode_projects = ''
        this.crud.form.show_only_erp = false
        this.crud.form.display_sales_detail = ''

        this.crud.form.erp_iva_condition = null
        this.crud.form.erp_iva_condition_id = 0       

        this.crud.form.accounting_accounts = null
        this.crud.form.accounting_accounts_id = 0 

        this.crud.form.type_instances = ''

        this.crud.form.observations = ''

        this.modal.form.title = "Nuevo Servicios"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.code = item.code
        this.crud.form.name = item.name                   
        this.crud.form.category = item.category
        this.crud.form.category_id = item.category_id
        this.crud.form.mode = item.mode
        this.crud.form.renewal = item.renewal
        this.crud.form.active = item.active
        this.crud.form.automatic_renewal = item.automatic_renewal
        this.crud.form.mode_projects = item.mode_projects
        this.crud.form.show_only_erp = item.show_only_erp
        this.crud.form.display_sales_detail = item.display_sales_detail

        this.crud.form.erp_iva_condition = item.erp_iva_condition
        this.crud.form.erp_iva_condition_id = item.erp_iva_condition_id
        
        this.crud.form.accounting_accounts = item.accounting_account
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id  

        this.crud.form.type_instances = item.type_instances == null ? '' : item.type_instances
        
        this.crud.form.observations = item.observations

        this.modal.form.title = "Editar Servicios"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Servicios',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarServices(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()              
              this.filterService()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarServices(this.crud.form);
        } else {
          var result = serviceAPI.agregarServices(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()          
          this.filterService()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },   
      loadCategory (object) {
        if(object){
          this.crud.form.category = object
          this.crud.form.category_id = object.id                   
        } else {
          this.crud.form.category = null
          this.crud.form.category_id = 0
        }
      }, 
      changeMode(){        
        if(this.crud.form.mode != 'days' ||
           this.crud.form.mode != 'months' ||
           this.crud.form.mode != 'years') {

          this.crud.form.renewal = 0
          this.crud.form.automatic_renewal = false

        } 
      },  
      getModeName(mode) {
        switch (mode) {
          case 'units':
            return 'UNIDAD/ES'
            break;
          
          case 'hours':
            return 'HORA/S'
            break;

          case 'days':
            return 'DÍA/S'
            break;

          case 'months':
            return 'MES/ES'
            break;

          case 'years':
            return 'AÑO/S'
            break;            
        }
      },

      filterLoad() {
        this.filterLoadCategory()
        
        this.restoreFilterStorage() 
      },
      filterLoadCategory() {
        var result = serviceAPI.obtenerCategory()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.category = []
          data.forEach(element => {            
            this.arr.filter.category.push({ code: element.id, label: element.name })            
          });          
        })   
      },      

      loadListPrice() {
        var result = serviceAPI.obtenerListaPrecios()

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {
            this.arr.select.listPrice.push({
              code: element.id,
              label: element.name
            })  
          });    
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },      
      setPricesServices(item) {
        this.crud.formPrice.id = 0
        this.crud.formPrice.services_id = item.id
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0

        this.tablePrices.items = this.orderPriceListByUtility(item.prices)
        this.itemSelected = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Mantenimiento de Precios"
      },
      editPricesServices(item) {
        this.crud.formPrice.id = item.id
        this.crud.formPrice.services_id = item.services_id
        this.crud.formPrice.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPrice.price_list_id = item.list.id
        this.crud.formPrice.amount = item.amount_total      
        this.crud.formPrice.amount_old = item.amount_total
      },    
      cancelSaveFormPrice(){
        this.crud.formPrice.id = 0
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
      },
      removePricesServices(item) {
        var id = item.id
        var listName = 'LISTA: ' + item.list.name        
        var listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:item.list.currency.code}).format(item.amount_total)

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item de la ' + listName + ' con ' + listAmount + '?', {
          title: 'Borrar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.eliminarPrecio(id);

            result.then((response) => {      
              loader.hide()              
              this.filterService(true)
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveFormPrice(){
        let loader = this.$loading.show();

        if(this.crud.formPrice.price_list_select) {
          this.crud.formPrice.price_list_id = this.crud.formPrice.price_list_select.code
        }        

        if (this.crud.formPrice.id) {
          var result = serviceAPI.editarPrecio(this.crud.formPrice)
        } else {
          var result = serviceAPI.agregarPrecio(this.crud.formPrice)
        }

        result.then((response) => {                         
          loader.hide()          
          this.filterService(true)
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },  
      orderPriceListByUtility(arrData) {
        let arrSort = arrData.map(item => {          
          return {
            ...item,
            utility: parseFloat(item.list.utility),
            currency_id: item.list.currency_id
          };
        });

        return Function.sortArrayIntegerMultiField(arrSort, ['currency_id','utility'], 1)
      },       
      
      loadIvaConditions (object) {
          if(object){
              this.crud.form.erp_iva_condition = object
              this.crud.form.erp_iva_condition_id = object.id             
          } else {
              this.crud.form.erp_iva_condition = null
              this.crud.form.erp_iva_condition_id = 0
          }
      },        
      loadAccountingAccounts (object) {
          if(object){
              this.crud.form.accounting_accounts = object
              this.crud.form.accounting_accounts_id = object.id             
          } else {
              this.crud.form.accounting_accounts = null
              this.crud.form.accounting_accounts_id = 0
          }
      },

      // PRESUPUESTOS
      setTemplate(item){
        this.modal.formTemplate.service = item
        
        this.modal.formTemplate.tabs = []
        this.modal.formTemplate.tabCounter = 0
        if(item.template_budget) {
          this.modal.formTemplate.tabs = JSON.parse(item.template_budget)
          this.modal.formTemplate.tabCounter = this.modal.formTemplate.tabs.length
        }

        this.modal.formTemplate.active = true
        this.modal.formTemplate.title = "Plantillas para Presupuesto"
      },
      newTemplateTab() {
        this.modal.formTemplate.tabs.push({
          id: this.modal.formTemplate.tabCounter++,
          reference: "Bloque " + this.modal.formTemplate.tabCounter,
          title: '',
          description: '',
        })       
      },         
      closeTemplateTab(item) {
        this.$bvModal.msgBoxConfirm('¿Desea borrar el bloque ' + item.reference + '?', {
          title: 'Borrar Bloque',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            for (let i = 0; i < this.modal.formTemplate.tabs.length; i++) {
              if (this.modal.formTemplate.tabs[i].id === item.id) {
                this.modal.formTemplate.tabs.splice(i, 1)
              }
            }
          } 
        })
        .catch(error => {               
          this.$awn.alert(Error.showError(error));
        })            
      },   
      saveTemplate() {
        this.$bvModal.msgBoxConfirm('¿Guardar plantilla de presupuesto para este servicio?', {
          title: 'Plantilla de Presupuesto',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                       
            let loader = this.$loading.show();            

            var template = JSON.stringify(this.modal.formTemplate.tabs)
            
            var result = serviceAPI.editarTemplateService({
              id: this.modal.formTemplate.service.id,
              template_budget: template,
            });
            
            result.then((response) => {
              this.modal.formTemplate.active = false
              loader.hide()
              this.filterService()
              this.$awn.success("Plantilla guardada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_services', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_services')) {
          this.filter = JSON.parse(Storage.getValue('filter_services'))
        }         
      },      
    }    
  }
</script>
<style>
  .crud-table-service-code {
    font-size: 12px;
    font-weight: bold;  
  }
  .crud-services-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-services-code {
    color: gray;
  }  
  .crud-services-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }       
  .crud-services-button-save-price {
    margin-top: 30px;
  }  
  .crud-services-list-prices-public {
    font-size: 10px;
    font-weight: 600;
    color: gray;
  }
  .crud-services-table-prices {
    min-height: 100px;
  }   
  .crud-services-legend-renewal {
    cursor: pointer;
  }
  .crud-services-table-project {
    cursor: pointer;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>
<style>
  .display-template-ql-editor .ql-editor {
    min-height: 350px !important;
  }  
  .services-template-budget .nav-link:hover {
    text-decoration: underline;
  }
  .popover-observations {
    cursor: pointer;
  }
</style>